// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-index-old-js": () => import("./../src/pages/index.old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-posts-index-js": () => import("./../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-posts-one-mdx": () => import("./../src/pages/posts/one.mdx" /* webpackChunkName: "component---src-pages-posts-one-mdx" */),
  "component---src-pages-posts-two-mdx": () => import("./../src/pages/posts/two.mdx" /* webpackChunkName: "component---src-pages-posts-two-mdx" */),
  "component---src-pages-test-mdx": () => import("./../src/pages/test.mdx" /* webpackChunkName: "component---src-pages-test-mdx" */)
}

